.navbar {
    height: 60px;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    background-color: white;
    position: sticky !important;
    z-index: 5;
    top: 0;

    .left {
        .logo {
            height: 40px;
            object-fit: contain;
        }
    }

    .right {
        .icons {
            svg {
                cursor: pointer;
                width: 20px;
                height: 20px;
                margin-right: 16px;
            }
        }

        .profilepic {
            cursor: pointer;
            margin-left: 8px;
            height: 40px;
            width: 40px;
            background-color: rgba(128, 128, 128, 0.297);
            border-radius: 100%;
            overflow: hidden;
            transition: all 1s;

            &:hover {
                background-color: $primaryColor;
                color: white !important;
            }
        }
    }
}


.footer {
    position: sticky;
    bottom: 0;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;

}

.title-custom {
    font-family: 'Open Sans', sans-serif !important;
    font-weight: 700;
}