@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@300;400;700;800&display=swap');

* {
    font-family: 'Nunito', sans-serif;
}

.pointer {
    cursor: pointer;
}

//SNA HORECAID
// $primaryColor : #244093;
// $dangerColor : #E54E26;

//SNA MINDSTORE
// .orangetheme {
//     $primaryColor: #E54E26;
//     $dangerColor: #244093;
// }

// .bluetheme {
//     $primaryColor: #E54E26;
//     $dangerColor: #244093;
// }




//   @if $theme == 'light' {
//     color: $light;
//   }

.sna-theme {
    $primaryColor: #244093;
    $dangerColor: #E54E26;
    @import './styles/overideColorTheme.scss';
    @import './styles/auth.scss';
    @import './styles/layout.scss';
    @import './styles/product.scss';
    @import './styles/shared.scss';
    @import './styles/play.scss';
}

.ham-theme {
    $primaryColor: #E54E26;
    $dangerColor: #244093;
    @import './styles/overideColorTheme.scss';
    @import './styles/auth.scss';
    @import './styles/layout.scss';
    @import './styles/product.scss';
    @import './styles/shared.scss';
    @import './styles/play.scss';
}



//import all scss
// @import './styles/auth.scss';
// @import './styles/layout.scss';
// @import './styles/product.scss';
// @import './styles/shared.scss';
// @import './styles/play.scss';