.auth {
    min-height: 100vh;

    h2 {
        font-weight: 800;
    }

    .logo {
        width: 150px;
        object-fit: contain;
    }

    .mslogo {
        width: 120px;
        object-fit: contain;
    }

    .bgauth {
        background: $primaryColor;
    }

    .formcon {
        min-height: 100vh;
        display: flex;
        justify-content: center;
        align-items: center;

        .login-regis {
            width: 100%;

        }
    }
}