.product {
    .category-tab {
        overflow-x: auto;
        position: sticky !important;
        top: 60px;

        .catbtn {
            .ant-radio-button {
                background-color: white !important;
                box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px !important;

            }
        }
    }
}