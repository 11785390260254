.layout {
    min-height: 100vh;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    h2 {
        font-weight: 800 !important;
    }
}