/////////// OVERIDE ANTD CSS /////////////////
/////////// OVERIDE ANTD CSS /////////////////
//BUTTOM

.ant-btn-primary {
    &:enabled {
        background-color: $primaryColor !important;
        box-shadow: $primaryColor !important;
        border-color: $primaryColor !important;
    }
}

.ant-btn-round {
    &:focus {
        border-color: $primaryColor !important;
        color: $primaryColor !important;
    }

    &:hover:enabled {
        border-color: $primaryColor !important;
        color: $primaryColor !important;
    }
}

//INPUT
.ant-input {
    border: 1px solid $primaryColor !important;

    &-status-error {
        border: 1px solid red !important;
    }
}


.ant-input-affix-wrapper {
    border: 1px solid $primaryColor !important;

    &-status-error {
        border: 1px solid red !important;
    }

    .ant-input {
        border: 0 !important;
    }
}

.ant-input-affix-wrapper-focused {
    // border: 1px solid green !important;
    box-shadow: $primaryColor !important;
}

//CHECKBOX
.ant-checkbox-wrapper {
    &:hover {
        .ant-checkbox-inner {
            border-color: $primaryColor !important;
        }
    }

    &:focus {
        .ant-checkbox-inner {
            border-color: $primaryColor !important;
        }
    }

    &-checked {

        .ant-checkbox-inner {
            background-color: $primaryColor !important;
            border-color: $primaryColor !important;
        }
    }
}


//RADIO GROUP
.ant-radio-button-wrapper {

    // border: 1px solid black !important;
    &:hover {
        &:enabled {
            color: $primaryColor !important;
        }
    }

    &-checked {
        &::before {
            background-color: unset !important;
        }

        color: white !important;
        background-color: $primaryColor !important;
        border-color: unset !important;
        font-weight: bold;

        &:hover {
            color: white !important;
        }

    }
}

.ant-radio-button-wrapper:focus-within {
    box-shadow: unset !important;
}

//PAGINATION
.ant-pagination-item-active {

    border-color: $primaryColor !important;
}

////////////////////////////
////////////////////////////

table {
    thead {
        tr {

            th {
                // font-family: 'Open Sans', sans-serif;
                font-weight: 700 !important;
                background: $primaryColor !important;
                color: white !important;
                text-transform: uppercase;
            }
        }
    }

    tbody {
        tr {
            td {
                font-size: 14px !important;

                p {
                    margin-bottom: 0 !important;
                }

            }
        }
    }

    .rowdark {
        background: whitesmoke;

        &:hover {
            background: whitesmoke !important;
        }
    }

}